import { Injectable } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/database";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})

export class EventService {
  lastTenAF: Observable<any[]>;
  lastTen: any[];
  announcementAF: Observable<any>;
  announcement: Announcement;
  constructor(db: AngularFireDatabase) {
    this.lastTenAF = db
      .list("/events", (ref) => ref.orderByChild("timestamp").limitToLast(30))
      .valueChanges();
    this.lastTenAF.subscribe((events) => {
      this.lastTen = events.reverse();
    });
    this.announcementAF = db.object<Announcement>("/announcement").valueChanges();
    this.announcementAF.subscribe((announcement) => {
      if(announcement && announcement.text && announcement.url && announcement.ctaText)
        this.announcement = this.truncateAnnouncementText(announcement);
      else
        this.announcement = null;
    });
  }

  private truncateAnnouncementText(announcement: Announcement): Announcement {
    const maxLength = 78;
    const totalLength = announcement.ctaText.length + announcement.text.length;
  
    if (totalLength > maxLength) {
      // Calculate how much we need to truncate
      const truncateLength = totalLength - maxLength + 3; // Add 3 for the ellipsis
  
      // Truncate the text and add an ellipsis
      announcement.text = `${announcement.text.substring(0, announcement.text.length - truncateLength)}...`;
    }
  
    return announcement;
  }
}

export interface Announcement {
  text: string;
  url: string;
  ctaText: string;
}

