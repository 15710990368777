import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireObject, AngularFireList } from '@angular/fire/database';
import firebase from 'firebase/app';
import { UtilService } from './util.service';
import { TrackingService } from './tracking.service';
import { AuthService } from './auth.service';
import { ProfileService } from './profile.service';
import { ClubService } from './club.service';
import { ApiService } from './api.service';
import { TranslateService } from '@ngx-translate/core';
import * as math from 'mathjs/dist/math';
import { Observable } from 'rxjs';

@Injectable()
export class LeagueService{
  leagueAF: AngularFireObject<any>;
  league: Observable<any>
	leagueID: string;
	latestLeague:any;
  rootRef: any;
  tournamentList:any[];
  recalcQueue: string[]
  prevLeagueID: string
  leagueSub: any
  loading: boolean = false

  constructor(
		private db: AngularFireDatabase,
		private util: UtilService,
		private tracking: TrackingService,
		private auth: AuthService,
		private profServ: ProfileService,
		private clubServ: ClubService,
		private translate: TranslateService,
    private api: ApiService
  	)
  { 
		math.import({
		  'import':     function () { throw new Error('Function import is disabled') },
		  'createUnit': function () { throw new Error('Function createUnit is disabled') },
		  'parse':      function () { throw new Error('Function parse is disabled') },
		  'simplify':   function () { throw new Error('Function simplify is disabled') },
		  'derivative': function () { throw new Error('Function derivative is disabled') }
		}, {override: true});
    this.rootRef = firebase.database().ref();
  }
  init(leagueID:string, recalc?:boolean){
    if(this.leagueSub)
      this.leagueSub.unsubscribe()
  	this.leagueID = leagueID;
		let path = 'leagues/' + leagueID;
    this.leagueAF = this.db.object(path);
    this.league = this.leagueAF.valueChanges();
    this.leagueSub = this.league.subscribe((results)=>{
			if(results){
				this.latestLeague = results;
        if(this.latestLeague.standings || recalc){
          recalc = false
          this.recalculate();
        }
        if(this.latestLeague && !this.latestLeague.rankings)
          this.api.recalculateLeagueStandings(this.leagueID)
      }
		})
    this.auth.addSub(this.leagueSub);
  }
  createLeague(leagueName:string, params:any){
		let leaguesRef = this.rootRef.child('leagues');
		let newKey = leaguesRef.push().key;
		let create = Date.now();
		let update: any = {
			leagueName: leagueName,
			leagueID: newKey,
			params: params,
      payouts: params.payouts,
      knockouts: params.knockouts,
			createDate: create,
			sort: -create
		};
		leaguesRef.child(newKey).set(update).then((data) => {
			this.init(newKey)
			this.tracking.track("Create League", update);
      this.api.sendEvent("EVT_NEW_LEAGUE", this.profServ.latestProfile)
		});
    delete update.params;
    let clubUpdate: any = {};
    clubUpdate['leagues/' + newKey] = update;
    this.clubServ.clubAF.update(clubUpdate);
  }

  calcFinish(finish:number, params:any, cost?:number, kills?:number){
  	let scope:any = {
  		p: params.playerCount,
  		b: params.buyin,
  		c: cost,
  		f: finish,
      k: kills,
      z: params.prizepool
  	}
    let result:number;
    if(!params.formula)
      params.formula = this.latestLeague.params.formula;
    if(params.formula.indexOf('z') != -1 && !params.prizepool)
      scope.z = 0
    try{
      result = math.round(math.eval(params.formula, scope),2);
      return result;
    }catch(err){
      console.log(err)
      return null
    }

  }
  getTourneyPoints(params:any){
  	if(params.playerCount){
	  	let points:number[] = [];
	  	for(var i=0; i<params.playerCount; i++)
	  		points.push(this.calcFinish(i+1,params,params.buyin,0))
	  	return points;  		
  	}
  }
  tournamentDateSort(a:any, b:any){
        return a.timestamp - b.timestamp;
   }

  updateLeagueTournNames(leagueName){
    this.api.updateLeagueTournNames(this.leagueID, leagueName, this.clubServ.clubID)
  }
  updateLeague(leagueName:string, scoringSystem:string, formula:string, payouts:boolean, knockouts:boolean,numberResults: number){
    let leagueUpdate:any = {leagueName: leagueName};
    leagueUpdate["params/scoringSystem"] = scoringSystem;
    leagueUpdate["params/formula"] = formula;
    leagueUpdate["params/payouts"] = payouts;
    leagueUpdate["params/knockouts"] = knockouts;
    if(numberResults > 0)
      leagueUpdate["params/numberResults"] = numberResults;
    let clubUpdate: any = {};
    clubUpdate['leagues/' + this.leagueID + "/leagueName"] = leagueName;
    clubUpdate['leagues/' + this.leagueID + "/payouts"] = payouts;
    clubUpdate['leagues/' + this.leagueID + "/knockouts"] = knockouts;
    if(this.latestLeague.leagueName != leagueName)
      this.updateLeagueTournNames(leagueName)
    this.clubServ.clubAF.update(clubUpdate);
  	this.leagueAF.update(leagueUpdate).then(()=>{
      setTimeout(()=>{
        this.recalculate();  
      })      
      this.api.sendEvent("EVT_LEAGUE_SETTINGS_UPDATED", this.profServ.latestProfile)
    });
   
  }
  resetLeagueRankings(){
    this.leagueAF.update({
      rankings: null
    }).then(()=>{
      this.recalculate()
    })
  }
  recalculate(leagueID?:string){
    if(!leagueID)
      leagueID = this.leagueID
    this.api.recalculateLeagueStandings(leagueID)
   }
  deleteLeague(){
    this.loading = true
    this.api.deleteLeague(this.leagueID, this.clubServ.clubID).then((res) => {
      this.loading = false
    })
  }
  recalculateAllLeagues(){
    for(var l in this.clubServ.latestClub.leagues)
      this.recalculate(l)
    this.clubServ.saveMemberProcessing = false
  }
}
