/*export const environment = {
	production: true,
	firebase: {
    apiKey: "AIzaSyBuGfn9MOnATI6EYsrRVPur7SYXjkd8kIw",
    authDomain: "blindvalet-production.firebaseapp.com",
    databaseURL: "https://blindvalet-production.firebaseio.com",
    projectId: "blindvalet-production",
    storageBucket: "blindvalet-production.appspot.com",
    messagingSenderId: "1072288907109"
	},
	server: "blindvalet.com",
	stripeClientKey: "pk_live_d3e2YweGFahflUaceunu2ZIn"
};*/

export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyBuGfn9MOnATI6EYsrRVPur7SYXjkd8kIw",
    //authDomain: "blindvalet-production.firebaseapp.com",
    authDomain: "blindvalet.com",
    databaseURL: "https://blindvalet-production.firebaseio.com",
    projectId: "blindvalet-production",
    storageBucket: "blindvalet-production.appspot.com",
    messagingSenderId: "1072288907109",
  },
  server: "blindvalet.com",
  stripeClientKey: "pk_live_d3e2YweGFahflUaceunu2ZIn",
  ravenID: "https://a444667a7ce7454595f991db1d207361@sentry.io/262509",
  lokitUID: "Lj1jZVTWujeSepZjFuRzb5XGB632",
  lokitProjectID: "-LK_lnFtzPbfWsfmU7sL",
};
