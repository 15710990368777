import { Injectable } from "@angular/core";
import firebase from "firebase/app";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TournamentService } from "./tournament.service";
import { ProfileService } from "./profile.service";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class LogProcessService {
  startTime: number;
  logRef: any;
  logsRef: any;
  payoutsRef: any;
  constructor(
    private snackBar: MatSnackBar,
    private tournServ: TournamentService,
    private profServ: ProfileService,
    private translate: TranslateService
  ) {
    this.logsRef = firebase.database().ref().child("logs");
    this.payoutsRef = firebase.database().ref().child("tournaments");
  }
  init(tournID: string) {
    this.startTime = Date.now();
    if (this.logRef) this.logRef.off();
    this.logRef = this.logsRef.child("tournaments").child(tournID);
    this.logRef.on("child_added", (snapshot) => {
      this.processLog(snapshot.val());
    });
    this.logRef.on("child_changed", (snapshot) => {
      this.processUndos(snapshot.val());
    });
    this.payoutsRef.child(tournID).on("child_changed", (snapshot) => {
      this.processPayouts(snapshot.val());
    });
    this.logRef = this.logsRef.child("tournaments").child(tournID);
  }
  processUndos(entry: any) {
    this.checkInfo();
  }
  processLog(entry: any) {
    if (entry.timestamp < this.startTime) return;
    else {
      this.checkInfo();
      if (entry.type == "ELIMINATION")
        setTimeout(() => {
          this.processElimination(entry);
        });

      if (entry.type == "DRAW_SEATS") {
        setTimeout(() => {
          this.processSeatDraw(entry);
        });
      }
      if (entry.type == "REGISTER" && !this.tournServ.latestLive?.seated) {
        this.translate.get(["PLAYER_REGISTERED"]).subscribe((res: any) => {
          if (entry && entry.undoParams) {
            this.snackBar.open(
              res["PLAYER_REGISTERED"] + ": " + entry.undoParams.name,
              null,
              {
                duration: 3000,
              }
            );
          }
        });
        if (this.tournServ.latestLive && this.tournServ.latestLive.out)
          this.tournServ.updateInfoEliminationFinish(1);
      }
      if (
        entry.type == "REBUY" ||
        entry.type == "REENTRY" ||
        entry.type == "ADDON"
      )
        this.checkRebuysInfo();

      let data: any = {
        repeat: false,
        base: false,
      };
      if (entry.type == "BREAK_TABLE")
        this.tournServ.addInfo("breakTable", data);
    }
  }
  checkRebuysInfo() {
    this.tournServ.addInfoIfMissing("rebuys", {
      repeat: true,
      base: true,
    });
  }
  processElimination(entry: any) {
    this.checkInfo();
    if (
      this.tournServ.latestTourn &&
      this.tournServ.latestTourn.params.manageRegistrations
    ) {
      let uid = this.profServ.uid;
      if (
        this.tournServ.latestTourn &&
        this.tournServ.latestTourn.players &&
        entry.undoParams &&
        entry.undoParams.uid == uid &&
        this.tournServ.latestTourn.players[uid]
      ) {
        let finish = this.tournServ.latestTourn.players[uid].finish;
        this.translate
          .get(["ELIMINATED_POSITION", "CLOSE"])
          .subscribe((res: any) => {
            this.snackBar.open(
              res["ELIMINATED_POSITION"] + ": " + finish,
              res["CLOSE"]
            );
          });
      }
      let data: any = {
        repeat: false,
        base: false,
      };
      if (
        this.tournServ.latestTourn &&
        this.tournServ.latestTourn.players &&
        entry.undoParams
      ) {
        data.undoParams = entry.undoParams;
      }
      this.tournServ.addInfoIfMissing("leaderboard", {
        repeat: true,
        base: true,
      });
      this.tournServ.addInfo("elimination", data);
    }
  }
  processSeatDraw(entry: any) {
    if (
      this.tournServ.latestTourn &&
      this.tournServ.latestTourn.players &&
      this.tournServ.latestTourn.players[this.profServ.uid] &&
      !this.tournServ.latestTourn.players[this.profServ.uid].finish
    )
      this.translate
        .get(["BEEN_SEATED", "CLOSE", "SEAT", "TABLE"])
        .subscribe((res: any) => {
          let myTable =
            this.tournServ.tableNames &&
            this.tournServ.tableNames[
              this.tournServ.latestTourn.players[this.profServ.uid].table - 1
            ]
              ? this.tournServ.tableNames[
                  this.tournServ.latestTourn.players[this.profServ.uid].table -
                    1
                ]
              : this.tournServ.latestTourn.players[this.profServ.uid].table;
          let message =
            res["BEEN_SEATED"] +
            " (" +
            res["TABLE"] +
            ":" +
            myTable +
            " " +
            res["SEAT"] +
            ":" +
            this.tournServ.latestTourn.players[this.profServ.uid].seat +
            ")";
          this.snackBar.open(message, res["CLOSE"]);
        });
  }

  processPayouts(entry: any) {
    this.checkInfo();
  }

  checkInfo() {
    if (this.tournServ.latestTourn && this.tournServ.latestTourn.params) {
      if (
        this.tournServ.latestTourn.params.numberPlayersFinalTable &&
        this.tournServ.latestTourn.playerCount -
          this.tournServ.latestTourn.live.out ==
          this.tournServ.latestTourn.params.numberPlayersFinalTable
      ) {
        this.tournServ.addInfoIfMissing("ft", {
          repeat: true,
          base: true,
        });
        this.tournServ.removeInfo({ compType: "ftBubble" });
      } else if (
        !this.tournServ.latestTourn.params.numberPlayersFinalTable &&
        this.tournServ.latestTourn.playerCount -
          this.tournServ.latestTourn.live.out ==
          this.tournServ.latestTourn.params.perTable
      ) {
        this.tournServ.addInfoIfMissing("ft", {
          repeat: true,
          base: true,
        });
        this.tournServ.removeInfo({ compType: "ftBubble" });
      } else this.tournServ.removeInfo({ compType: "ft" });

      if (
        this.tournServ.latestTourn.params.numberPlayersFinalTable &&
        this.tournServ.latestTourn.playerCount -
          this.tournServ.latestTourn.live.out -
          1 ==
          this.tournServ.latestTourn.params.numberPlayersFinalTable
      ) {
        this.tournServ.addInfoIfMissing("ftBubble", {
          repeat: true,
          base: true,
        });
        this.tournServ.removeInfo({ compType: "ft" });
      } else if (
        this.tournServ.latestTourn &&
        !this.tournServ.latestTourn.params.numberPlayersFinalTable &&
        this.tournServ.latestTourn.playerCount -
          this.tournServ.latestTourn.live.out -
          1 ==
          this.tournServ.latestTourn.params.perTable
      ) {
        this.tournServ.addInfoIfMissing("ftBubble", {
          repeat: true,
          base: true,
        });
        this.tournServ.removeInfo({ compType: "ft" });
      } else this.tournServ.removeInfo({ compType: "ftBubble" });
    }

    if (this.tournServ.latestTourn) {
      const cnt = this.tournServ.latestTourn.params.manageRegistrations
        ? this.tournServ.latestTourn.playerCount
        : this.tournServ.latestTourn.params.actPlayers;
      if (
        this.tournServ.latestLive &&
        cnt - this.tournServ.latestTourn.live.out - 1 ==
          this.tournServ.latestTourn.params.placesPaid
      ) {
        this.tournServ.addInfoIfMissing("moneyBubble", {
          repeat: true,
          base: true,
        });
        this.tournServ.removeInfo({ compType: "itm" });
      } else if (
        cnt - this.tournServ.latestTourn.live.out - 1 !=
        this.tournServ.latestTourn.params.placesPaid
      )
        this.tournServ.removeInfo({ compType: "moneyBubble" });

      if (
        this.tournServ.latestLive?.itm &&
        this.tournServ.latestTourn.params.tournStatus != "Finished"
      ) {
        this.tournServ.removeInfo({ compType: "moneyBubble" });
        this.tournServ.addInfoIfMissing("itm", {
          repeat: true,
          base: true,
        });
      } else if (
        this.tournServ.latestTourn.playerCount -
          this.tournServ.latestTourn.live.out -
          1 >=
        this.tournServ.latestTourn.params.placesPaid
      )
        this.tournServ.removeInfo({ compType: "itm" });
    }
  }
}
