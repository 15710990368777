import { Component, OnInit } from '@angular/core';
import { ClubService } from '../../providers/club.service';
import { RoutingService } from '../../providers/routing.service'
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-max-players',
  templateUrl: './max-players.component.html',
  styleUrls: ['./max-players.component.css']
})
export class MaxPlayersComponent implements OnInit {

  constructor(
    public clubServ: ClubService,
    private routing: RoutingService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
  }
  goSubscription(){
    this.routing.toSubscription()
    this.dialog.closeAll()
  }
}
