	<h1 mat-dialog-title>
		<span *ngIf="data.tableBreaking">{{'BREAKING_TABLE' | translate}}</span>
		<span *ngIf="!data.tableBreaking">{{'MANUAL_SEAT_CHANGE' | translate}}</span>
	</h1>
	<div mat-dialog-content tdMediaToggle="gt-xs" [mediaClasses]="['min-600']">
		<div *ngIf="data.tableBreaking" layout="row">
			<div flex="50" layout="column" layout-align="center center"><img src="/assets/img/badge-break-table.png" class="info-badge" tdMediaToggle="xs" [mediaClasses]="['info-badge-xs']" ></div>
			<div flex="50" layout="column" layout-align="center center">
				<div flex="20"><h2>Table</h2></div>
				<div flex="80" class="weight-800 tc-bv-orange full-width">
					<app-resize-text [textString]="data.tableBreaking"></app-resize-text>
				</div>				
			</div>
		</div>
			<table class="full-width">
				<th></th>
				<th></th>
				<th class="soft-blue" *ngIf="!data.tableBreaking">{{'TABLE' | translate}}</th>
				<th class="soft-blue">{{'SEAT' | translate}}</th>
				<th></th>
				<th>{{'TABLE' | translate}}</th>
		    <th>{{'SEAT' | translate}}</th>
		      <tr *ngFor="let move of data.playerMoves">
		        <td><img class="table-avatar" src="{{move.imgURL}}"></td>
		        <td><h2 hide-xs>{{move.dispName}}</h2><h4 hide-gt-xs>{{move.dispName}}</h4></td>
		        <td class="soft-blue" align="center" *ngIf="!data.tableBreaking">{{move.from.table}}</td>
		        <td class="soft-blue" align="center">{{move.from.seat}}</td>
		        <td align="center"><mat-icon color="accent">arrow_forward</mat-icon></td>
		        <td class="mat-title" align="center">{{move.to.table}}</td>
		        <td class="mat-title" align="center">{{move.to.seat}}</td>
		      </tr>      
		 </table>	
	</div>

	<div mat-dialog-actions>
		<app-close-dialog-button></app-close-dialog-button>
	</div>

