import { Component, OnInit } from '@angular/core';
import { Event } from '@angular/router';
import { EventService } from 'src/app/providers/event.service';

@Component({
  selector: 'app-live',
  templateUrl: './live.component.html',
  styleUrls: ['./live.component.css']
})
export class LiveComponent implements OnInit {
  eventIcons = {
    EVT_ADD_TABLE: "add_circle_outline",
    EVT_NEXT_LEVEL: "update",
    EVT_PLAYER_ADDON: "control_point_duplicate",
    EVT_PLAYER_ELIMINATED: "remove_circle_outline",
    EVT_PLAYER_REBOUGHT: "radio_button_checked",
    EVT_PLAYER_REGISTERED: "account_circle",
    EVT_SEATS_DRAWN: "person_pin_circle",
    EVT_TOURNAMENT_CREATED: "control_point",
    EVT_TOURNAMENT_STARTED: "play_circle_outline",
    EVT_CLUB_SETTINGS_UPDATED: "settings",
    EVT_CLUB_LOGO_UPDATED: "image",
    EVT_CLOCK_BACKGROUND_UPDATED: "image",
    EVT_NEW_LEAGUE: "queue",
    EVT_LEAGUE_SETTINGS_UPDATED: "settings",
    EVT_NEW_USER: "person_add"
   }
  constructor(public eventServ:EventService) { }

  ngOnInit() {

  }

}
