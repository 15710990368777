import { Injectable } from "@angular/core";
import {
  AngularFireDatabase,
  AngularFireObject,
  AngularFireList,
} from "@angular/fire/database";
import firebase from "firebase/app";
import { AuthService } from "./auth.service";
import { TranslateService } from "@ngx-translate/core";
import { UtilService } from "./util.service";
import { TrackingService } from "./tracking.service";
import { ApiService } from "./api.service";
import { Md5 } from "ts-md5/dist/md5";
import { defaults } from "../shared/defaults";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";
import { ConfigService } from "./config.service";
import { Observable, Subject } from "rxjs";

@Injectable()
export class ClubService {
  clubAF: AngularFireObject<any>;
  club: Observable<any>;
  rootRef: any;
  tournamentsAF: AngularFireList<any>;
  tournaments: Observable<any[]>;
  leaguesAF: AngularFireList<any>;
  leagues: Observable<any[]>;
  membersAF: AngularFireList<any>;
  members: Observable<any[]>;
  joinRequestsAF: AngularFireList<any>;
  joinRequests: Observable<any[]>;
  latestJoinRequests: any[];
  clubOwner: boolean = true;
  clubStaff: boolean = true;
  latestClub: any;
  clubRef: any;
  clubID: string;
  roles: any = {};
  pubRef: any;
  lobbyIndex: number = 0;
  latestLeagues: any[];
  currentLeague: any;
  latestTournaments: any[];
  latestMembers: any[];
  clubInitObs: Observable<any[]>;
  saveMemberProcessing: boolean = false;
  editMember: any;
  loadingID: string;
  deletedClub: string;
  membersOrderPerPage: any[];
  loadingItemsOrderPerPage: boolean = false;
  tournamentsOrderPerPage: any[];
  loadingDenyRequest: boolean = false;

  private clubInits = new Subject<any>();
  constructor(
    private db: AngularFireDatabase,
    private auth: AuthService,
    private translate: TranslateService,
    private util: UtilService,
    private tracking: TrackingService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private configServ: ConfigService,
    private api: ApiService
  ) {
    let th = this;
    this.rootRef = firebase.database().ref();
    setTimeout(() => {
      translate.get(["MEMBER", "OWNER", "STAFF"]).subscribe((res: any) => {
        th.roles = {
          Member: res["MEMBER"],
          Owner: res["OWNER"],
          Staff: res["STAFF"],
        };
      });
    });

    this.pubRef = this.rootRef.child("public");
    this.clubInitObs = this.clubInits.asObservable();
  }

  changeTournStatusInClub(tournID: string, status: string) {
    if (tournID && status) {
      let update: any = {};
      update[`tournaments/${tournID}/status`] = status;
      this.clubAF.update(update);
    }
  }

  updateClubLevel(owner: string) {
    if (owner == this.auth.uid) {
      this.rootRef
        .child("profiles")
        .child(this.auth.uid)
        .child("subscription")
        .child("code")
        .once("value", (snapshot) => {
          let planID: string = snapshot.val();
          if (!planID) planID = "KITCHEN_TABLE";
          let plan: any = this.configServ.getPlan(planID);
          if (
            (plan && this.latestClub.subLevel != plan.level) ||
            this.latestClub.player_limit != plan.player_limit
          )
            this.clubAF.update({
              subLevel: plan.level,
              player_limit: plan.player_limit,
            });
        });
    }
  }
  init(clubID: string) {
    this.clubID = clubID;
    this.lobbyIndex = 0;
    let path = "clubs/" + clubID;
    this.clubAF = this.db.object(path);
    this.club = this.clubAF.valueChanges();
    let clubSub = this.club.subscribe((results) => {
      if (results) {
        this.latestClub = results;
        if (this.latestClub && this.latestClub.owner) {
          this.clubOwner = this.isClubOwner();
          this.clubStaff = this.isClubStaff();
          this.updateClubLevel(this.latestClub.owner);
        }
        this.clubInits.next(clubID);
      }
    });
    this.auth.addSub(clubSub);
    this.tournamentsAF = this.db.list(path + "/tournaments", (ref) =>
      ref.orderByChild("tournTimestamp")
    );
    this.tournaments = this.tournamentsAF.valueChanges();
    this.membersAF = this.db.list(path + "/members");
    this.members = this.membersAF.valueChanges();
    this.joinRequestsAF = this.db.list(path + "/joinRequests");
    this.joinRequests = this.joinRequestsAF.valueChanges();
    let jrSub = this.joinRequests.subscribe((results) => {
      this.latestJoinRequests = results;
    });
    this.leagues = this.db
      .list(path + "/leagues", (ref) => ref.orderByChild("sort"))
      .valueChanges();
    let leaguesSub = this.leagues.subscribe((results) => {
      if (results) this.latestLeagues = results;
    });
    let clubTourneysSub = this.tournaments.subscribe((results) => {
      if (results) {
        this.latestTournaments = results;
      }
    });
    let membersSub = this.members.subscribe((results) => {
      if (results && results.length) {
        this.latestMembers = results;
      }
    });
    this.getFirstItemsPage("tournaments", 8);
    this.auth.addSub(leaguesSub);
    this.auth.addSub(clubTourneysSub);
    this.auth.addSub(membersSub);
    this.auth.addSub(jrSub);
    this.clubRef = firebase.database().ref(path);
    return clubID;
  }
  private isClubOwner() {
    if (
      this.latestClub &&
      this.latestClub.owner &&
      this.latestClub.owner == this.auth.uid
    )
      return true;
    else return false;
  }
  private isClubStaff() {
    if (this.latestClub && this.auth.uid) {
      if (this.latestClub.owner && this.latestClub.owner == this.auth.uid)
        return true;
      else if (
        this.latestClub.members &&
        this.latestClub.members[this.auth.uid] &&
        (this.latestClub.members[this.auth.uid].role == "Owner" ||
          this.latestClub.members[this.auth.uid].role == "Staff")
      )
        return true;
    }
    return false;
  }
  noClub() {
    this.club = null;
    this.tournaments = null;
  }
  deleteTournament(tournID: string) {
    for (var i = 0; i < this.latestTournaments.length; i++)
      if (this.latestTournaments[i].tournID == tournID) {
        this.latestTournaments.splice(i, 1);
        break;
      }
    this.loadingID = tournID;
    setTimeout(() => {
      this.api.deleteTournament(tournID, this.clubID).then((res) => {
        this.loadingID = "";
      });
    });
  }
  getManualID(playerName: string) {
    if (!playerName || this.latestClub.members[this.clubID + "-" + playerName])
      return null;
    else {
      let newUID = this.clubID + "-" + playerName;
      newUID = newUID.replace(/[.$\[\]#\/]/g, "-");
      return newUID;
    }
  }
  joinNew(playerName: string) {
    let newUID: string = this.getManualID(playerName);
    if (!newUID) return false;
    else {
      let update: any = {};
      update["members/" + newUID] = {
        memberName: playerName,
        sortName: playerName.toLowerCase(),
        imgURL: defaults.profileImage,
        role: "Member",
        manual: true,
        uid: newUID,
      };
      this.clubAF.update(update);
      return newUID;
    }
  }
  setTournPlayerCount(tournID: string, playerCount: number) {
    this.clubRef
      .child("tournaments")
      .child(tournID)
      .child("playerCount")
      .set(playerCount);
  }
  quickClub(
    dispName: string,
    subLevel: number,
    player_limit: number,
    imgURL?: string
  ) {
    let clubName = "Club " + dispName;
    this.newClub(
      clubName,
      dispName,
      subLevel,
      player_limit,
      imgURL || "/assets/img/anonymous.png"
    );
  }
  newClub(
    clubName: string,
    dispName: string,
    subLevel: number,
    player_limit: number,
    imgURL?: string
  ) {
    let clubID: string = this.util.randomCode(4);
    let baseRef = firebase.database().ref();
    let newClubRef = baseRef.child("clubs").child(clubID);
    newClubRef.once("value", (snapshot) => {
      if (snapshot.val())
        this.newClub(
          clubName,
          dispName,
          subLevel,
          player_limit,
          imgURL || "/assets/img/anonymous.png"
        );
      else {
        let membersObj: any = {};
        membersObj[this.auth.uid] = {
          uid: this.auth.uid,
          memberName: dispName,
          sortName: dispName.toLowerCase(),
          role: "Owner",
          imgURL: imgURL || "/assets/img/anonymous.png",
        };
        let clubObj: any = {
          clubID: clubID,
          owner: this.auth.uid,
          clubName: clubName,
          members: membersObj,
          subLevel: subLevel,
          player_limit: player_limit,
        };
        let profClubObj: any = {
          clubID: clubID,
          clubName: clubName,
          role: "Owner",
        };
        let pubUpdate: any = {
          clubID: clubID,
          owner: this.auth.uid,
          clubName: clubName,
        };
        let update: any = {};
        update["clubs/" + clubID] = clubObj;
        update["profiles/" + this.auth.uid + "/clubs/" + clubID] = profClubObj;
        baseRef.update(update).then((data) => {
          this.init(clubID);
          this.pubRef.child(clubID).set(pubUpdate);
        });
        this.tracking.track("New Club", { $name: clubName, clubID: clubID });
      }
    });
  }
  updateClub(newName: string, logoURL?: string, backURL?: string) {
    let params: any = {
      clubID: this.clubID,
      clubName: newName,
    };
    this.latestClub.clubName = newName;
    if (logoURL) {
      this.latestClub.logoURL = logoURL;
      params.logoURL = logoURL;
    }
    if (backURL) {
      this.latestClub.backgroundURL = backURL;
      params.backgroundURL = backURL;
    }
    this.api.updateClub(params);
  }
  async uploadClubLogo(file: File) {
    var profImageRef = firebase
      .storage()
      .ref()
      .child(this.clubID)
      .child("clubLogo");
    return profImageRef
      .put(file)
      .then(async function (snapshot) {
        return snapshot.ref.getDownloadURL().then((downloadURL) => {
          return downloadURL;
        });
      })
      .catch((error) => {
        return error;
      });
  }
  async uploadBackImg(file: File) {
    var profImageRef = firebase
      .storage()
      .ref()
      .child(this.clubID)
      .child("clubBackground");
    return profImageRef
      .put(file)
      .then(async function (snapshot) {
        return snapshot.ref.getDownloadURL().then((downloadURL) => {
          return downloadURL;
        });
      })
      .catch((error) => {
        return error;
      });
  }
  removeMemberClubImages(imageType: string) {
    this.api.clubRemoveMemberImages(this.clubID, imageType);
  }
  removeClubImage(clubImgType: string) {
    var profImageRef;
    if (clubImgType == "CLOCK_BACKGROUND_IMAGE") {
      profImageRef = firebase
        .storage()
        .ref()
        .child(this.clubID)
        .child("clubBackground");
      this.clubAF.update({
        backgroundURL: null,
      });
      this.removeMemberClubImages("backgroundURL");
    } else {
      profImageRef = firebase
        .storage()
        .ref()
        .child(this.clubID)
        .child("clubLogo");
      this.clubAF.update({
        logoURL: null,
      });
      this.removeMemberClubImages("logoURL");
    }
    profImageRef.delete();
  }

  removeMember(member: any) {
    let clubUpdates = {};
    clubUpdates[`clubs/${this.clubID}/members/${member.uid}`] = null;
    if (!member.manual) this.api.profRemoveClub(this.clubID, member.uid);

    this.rootRef.update(clubUpdates);
  }
  createInvite(email: string): string {
    let inviteRef = this.pubRef.child(this.clubID).child("invites").push({
      email,
      time: Date.now(),
    });
    let newInvite = inviteRef.key;
    return newInvite;
  }
  getMemberImage(uid: string): string {
    if (
      uid &&
      this.latestClub &&
      this.latestClub.members &&
      this.latestClub.members[uid] &&
      this.latestClub.members[uid].imgURL
    )
      return this.latestClub.members[uid].imgURL;
    else return "/assets/img/badge-players.png";
  }
  deleteClub() {
    let clubID = this.clubID;
    this.deletedClub = clubID;
    let clubName = this.latestClub.clubName;
    this.api.deleteClub(clubID);
    this.translate.get(["CLUB_DELETED"]).subscribe((res: any) => {
      this.snackBar.open(res["CLUB_DELETED"] + ": " + clubName, null, {
        duration: 4000,
      });
    });
    this.tracking.track("Delete Club", { clubID: clubID });
  }
  denyRequest(request: any) {
    this.loadingDenyRequest = true;
    this.api.deleteRequest(this.clubID, request.uid).then(() => {
      this.loadingDenyRequest = false;
    });
    this.translate.get(["REQUEST_DENIED"]).subscribe((res: any) => {
      this.snackBar.open(
        res["REQUEST_DENIED"] +
          ": " +
          request.dispName +
          "(" +
          request.email +
          ")",
        null,
        {
          duration: 4000,
        }
      );
    });
  }

  minClubLevel(level: number) {
    if (this.latestClub && this.latestClub.subLevel >= level) return true;
    else return false;
  }
  getPlayerLimit() {
    if (this.latestClub && this.latestClub.player_limit > 0)
      return this.latestClub.player_limit;
    else return null;
  }
  updateTournParams(tournID: string, paramsUpdateValues: any) {
    if (this.clubRef && tournID)
      this.clubRef
        .child("tournaments")
        .child(tournID)
        .child("params")
        .update(paramsUpdateValues);
  }
  changeMemberNameInTournament(tournID, uid, memberName) {
    let th = this;
    setTimeout(() => {
      this.rootRef
        .child("tournaments")
        .child(tournID)
        .transaction((tournament) => {
          if (tournament && tournament.players) {
            for (var p in tournament.players)
              if (p == uid) {
                tournament.players[p].dispName = memberName;
                if (tournament.tables)
                  for (var t in tournament.tables)
                    if (tournament.tables[t].seats)
                      for (var s in tournament.tables[t].seats)
                        if (tournament.tables[t].seats[s].uid == uid) {
                          tournament.tables[t].seats[s].dispName = memberName;
                        }
              }
          }
          return tournament;
        });
    });
  }
  updateMemberName(member: any) {
    var upMemPromise = new Promise((resolve, reject) => {
      let update: any = {};
      let memberPath = "members/" + member.uid + "/";
      update[memberPath + "memberName"] = member.tempName;
      update[memberPath + "sortName"] = member.tempName.toLowerCase();
      this.clubAF.update(update);
      for (var t in this.latestClub.tournaments) {
        if (
          this.latestClub.tournaments[t].params.manageRegistrations &&
          this.latestClub.tournaments[t].playerCount
        ) {
          this.changeMemberNameInTournament(t, member.uid, member.tempName);
        }
      }
      resolve("done");
    });
    return upMemPromise;
  }
  setTournFinishTime(tournID: string, finishTime: number) {
    let update: any = {};
    update["tournaments/" + tournID + "/finishTime"] = finishTime;
    this.clubAF.update(update);
  }
  saveMember() {
    if (
      this.editMember &&
      this.editMember.uid &&
      this.editMember.memberName &&
      this.editMember.memberName.length
    ) {
      let update = {};
      let creating: boolean = false;
      if (!this.latestClub.members[this.editMember.uid]) creating = true;
      if (
        this.editMember.memberName &&
        this.editMember.memberName.length &&
        (creating ||
          this.latestClub.members[this.editMember.uid].memberName !=
            this.editMember.memberName)
      ) {
        update["members/" + this.editMember.uid + "/memberName"] =
          this.editMember.memberName;
        update["members/" + this.editMember.uid + "/sortName"] =
          this.editMember.memberName.toLowerCase();
      }
      if (
        creating ||
        this.latestClub.members[this.editMember.uid].role !=
          this.editMember.role
      )
        update["members/" + this.editMember.uid + "/role"] =
          this.editMember.role;
      if (this.editMember.croppedImage) {
        var memberImgRef = firebase
          .storage()
          .ref()
          .child(this.clubID)
          .child("members")
          .child(this.editMember.uid)
          .child("memberImg");
        let th: any = this;
        memberImgRef
          .putString(this.editMember.croppedImage, "data_url")
          .then(() => {
            memberImgRef.getDownloadURL().then(function (url) {
              update["members/" + th.editMember.uid + "/imgURL"] = url;
              th.clubAF.update(update);
            });
          });
      } else if (creating) {
        update["members/" + this.editMember.uid + "/imgURL"] =
          this.editMember.imgURL;
      }
      if (creating) {
        update["members/" + this.editMember.uid + "/manual"] =
          this.editMember.manual;
        update["members/" + this.editMember.uid + "/uid"] = this.editMember.uid;
      }
      if (Object.keys(update).length) {
        this.clubAF.update(update);
      }
    } else alert("Error saving member");
  }
  addMember() {
    let newUID: string = this.getManualID(this.editMember.memberName);
    if (
      newUID &&
      this.editMember.memberName &&
      this.editMember.memberName.length
    ) {
      this.editMember.uid = newUID;
      this.saveMember();
    } else {
      alert("Error adding member");
    }
    this.getFirstItemsPage("members", 14);
  }

  getFirstItemsPage(type, itemsPerPage) {
    if (this.clubID) {
      let firstItemsSub;
      this.loadingItemsOrderPerPage = true;
      if (type == "members") {
        firstItemsSub = this.db
          .list("clubs/" + this.clubID + "/" + type, (ref) =>
            ref.orderByKey().startAt("0").limitToFirst(Number(itemsPerPage))
          )
          .valueChanges()
          .subscribe((res) => {
            if (res) {
              this.membersOrderPerPage = res;
              this.loadingItemsOrderPerPage = false;
            }
          });
      } else if (type == "tournaments") {
        const now = Date.now();
        const oneWeekAgo = now - 7 * 24 * 60 * 60 * 1000;
        firstItemsSub = this.db
          .list("clubs/" + this.clubID + "/" + type, (ref) =>
            ref
              .orderByChild("tournTimestamp")
              .endAt(oneWeekAgo)
              .limitToLast(Number(itemsPerPage))
          )
          .valueChanges()
          .subscribe((res) => {
            if (res) {
              this.tournamentsOrderPerPage = res;
              this.loadingItemsOrderPerPage = false;
            }
          });
      }
      if (firstItemsSub) this.auth.addSub(firstItemsSub);
    }
  }

  getLastItemsPage(type, num) {
    this.loadingItemsOrderPerPage = true;
    let lastItemsSub;
    if (type == "members") {
      lastItemsSub = this.db
        .list("clubs/" + this.clubID + "/" + type, (ref) =>
          ref.orderByKey().limitToLast(Number(num))
        )
        .valueChanges()
        .subscribe((res) => {
          if (res) {
            this.membersOrderPerPage = res;
            this.loadingItemsOrderPerPage = false;
          }
        });
    } else if (type == "tournaments") {
      let now = Date.now();
      now -= 7 * 24 * 60 * 60 * 1000;
      lastItemsSub = this.db
        .list("clubs/" + this.clubID + "/" + type, (ref) =>
          ref
            .orderByChild("tournTimestamp")
            .endAt(now)
            .limitToFirst(Number(num))
        )
        .valueChanges()
        .subscribe((res) => {
          if (res) {
            this.tournamentsOrderPerPage = res;
            this.loadingItemsOrderPerPage = false;
          }
        });
    }
    if (lastItemsSub) this.auth.addSub(lastItemsSub);
  }

  getItemsPerPage(itemId, event, type, itemsPerPage) {
    this.loadingItemsOrderPerPage = true;
    let newItemsSub;
    if (
      type == "members" &&
      this.membersOrderPerPage &&
      this.membersOrderPerPage.length
    ) {
      if (event == "nextPage") {
        newItemsSub = this.db
          .list("clubs/" + this.clubID + "/" + type, (ref) =>
            ref
              .orderByKey()
              .startAfter(`${itemId}`)
              .limitToFirst(Number(itemsPerPage))
          )
          .valueChanges()
          .subscribe((res) => {
            if (res) {
              this.membersOrderPerPage = res;
              this.loadingItemsOrderPerPage = false;
            }
          });
      } else if (event == "previousPage") {
        newItemsSub = this.db
          .list("clubs/" + this.clubID + "/" + type, (ref) =>
            ref
              .orderByKey()
              .endBefore(`${itemId}`)
              .limitToLast(Number(itemsPerPage))
          )
          .valueChanges()
          .subscribe((res) => {
            if (res) {
              this.membersOrderPerPage = res;
              this.loadingItemsOrderPerPage = false;
            }
          });
      }
    } else if (
      type == "tournaments" &&
      this.tournamentsOrderPerPage &&
      this.tournamentsOrderPerPage.length
    ) {
      let now = Date.now();
      now -= 7 * 24 * 60 * 60 * 1000;
      if (event == "nextPage") {
        newItemsSub = this.db
          .list("clubs/" + this.clubID + "/tournaments", (ref) =>
            ref
              .orderByChild("tournTimestamp")
              .endBefore(itemId)
              .limitToLast(Number(itemsPerPage))
          )
          .valueChanges()
          .subscribe((res) => {
            if (res) {
              this.tournamentsOrderPerPage = res;
              this.loadingItemsOrderPerPage = false;
            }
          });
      } else if (event == "previousPage") {
        newItemsSub = this.db
          .list("clubs/" + this.clubID + "/tournaments", (ref) =>
            ref
              .orderByChild("tournTimestamp")
              .startAfter(itemId)
              .endAt(now)
              .limitToFirst(Number(itemsPerPage))
          )
          .valueChanges()
          .subscribe((res) => {
            if (res) {
              this.tournamentsOrderPerPage = res;
              this.loadingItemsOrderPerPage = false;
            }
          });
      }
    }
    if (newItemsSub) this.auth.addSub(newItemsSub);
  }
}
