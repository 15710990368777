import { Component, OnInit, ViewChild, Input, OnDestroy } from '@angular/core';
import { UtilService } from '../../providers/util.service';
import { TournamentService } from '../../providers/tournament.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-resize-text',
  templateUrl: './resize-text.component.html',
	styleUrls: ['./resize-text.component.scss'],
	host: {
		'(window:resize)': 'onResize($event)'
	}

})
export class ResizeTextComponent implements OnInit, OnDestroy {
	@ViewChild('growWrapper', { static: true }) growWrapper;
	@ViewChild('growText', { static: true }) growText;
	@Input() textString:string;
	@Input() leadIcon: string;
	textStyle: any;
	tickSub: Subscription;
	constructor(
		public util: UtilService,
		private tournServ: TournamentService
		) { }

  ngOnInit() {
  	this.tickSub = this.tournServ.tickObs.subscribe((tick)=>{
			this.sizeClockText();
  	})
  }
	onResize(event) {
		this.sizeClockText();
	}
	sizeClockText() {
		if(this.textString){
			if (this.tickSub)
					this.tickSub.unsubscribe();
			setTimeout(() => {
				if (this.growWrapper.nativeElement.offsetHeight && this.growText.nativeElement.offsetHeight) {
					let newStyle = this.util.getFontSizeStyle(this.growWrapper.nativeElement, this.growText.nativeElement, this.leadIcon);
					this.textStyle = newStyle;
				}
			});
		}
	}
	ngOnDestroy(){
		if (this.tickSub)
			this.tickSub.unsubscribe();
	}
}
