<div class="event-list pad-md back-800">
  <h1 class="tc-bv-orange">Live feed</h1>
  <div class="event-item" *ngFor="let event of eventServ.lastTen; let i=index" layout="row">
    <div class="event-icon soft-blue">
      <mat-icon>{{eventIcons[event.messageId]}}</mat-icon>
    </div>
    <div class="event-body pad-top-xs pad-left-md pad-bottom-md pas-right-md" flex layout="column">
      <div class="event-message">
        {{event.messageId | translate}}
      </div>
      <div class="tc-bv-orange text-md pad-top-xs" layout="row" layout-align="start top">
        <div><mat-icon class="text-xxl">place</mat-icon></div>
        <div>{{event.city | capFirstLetters}}<span *ngIf="event.state">, {{event.state}}</span>, {{event.country}}</div>
      </div>
    </div>
    <div class="event-time" layout="column">
      <div class="soft-blue text-sm pad-top-xs pad-left pad-bottom-xs">
        {{ event.timestamp | date:'shortTime' }}
      </div>
      <div *ngIf="event.count" class="pad-left">
        <mat-chip-list>
          <mat-chip color="accent" selected>
            <span class="text-md">x</span>{{event.count}}
          </mat-chip>
        </mat-chip-list>
      </div>
    </div>
    <div class="event-line"></div>
  </div>
</div>