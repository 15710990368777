import { Injectable } from "@angular/core";
import { ProfileService } from "./profile.service";
import { ConfigService } from "./config.service";
import { AngularFireDatabase, AngularFireObject } from "@angular/fire/database";
import { environment } from "../../environments/environment";
import { AuthService } from "./auth.service";
import { RoutingService } from "./routing.service";
import { ApiService } from "./api.service";
import { UtilService } from "./util.service";
import { MatDialog } from "@angular/material/dialog";
import { CheckoutComponent } from "../subscription/checkout/checkout.component";

@Injectable()
export class SubscriptionService {
  card: any;
  stripe: any;
  cardError: string;
  latestProration: any;
  openPayInv: boolean = false;
  latestCard: any;
  constructor(
    private db: AngularFireDatabase,
    public configServ: ConfigService,
    public profServ: ProfileService,
    private auth: AuthService,
    private routing: RoutingService,
    private dialog: MatDialog,
    private api: ApiService,
    private util: UtilService
  ) {}
  initStripe() {
    this.stripe = (<any>window).Stripe(environment.stripeClientKey);
    let elements = this.stripe.elements();
    // Create an instance of the card Element
    this.card = elements.create("card", {
      iconStyle: "solid",
      style: {
        base: {
          iconColor: "#F99A52",
          color: "#32315E",
          lineHeight: "32px",
          fontWeight: 400,
          fontFamily: '"Open Sans", sans-serif',
          fontSize: "16px",

          "::placeholder": {
            color: "#888",
          },
        },
        invalid: {
          iconColor: "#e85746",
          color: "#e85746",
        },
      },
      classes: {
        focus: "is-focused",
        empty: "is-empty",
      },
    });
  }
  subscribe(plan: any, interval: string, coupon?: string) {
    this.profServ.sub_processing = true;
    this.cardError = null;
    let change: any = {
      plan: plan.code,
      interval: interval,
      currency: this.profServ.latestProfile.currency,
      level: plan.level,
      player_limit: plan.player_limit,
    };
    if (this.profServ.latestProfile.vat && this.profServ.amountDue > 0)
      change.vat = this.profServ.latestProfile.vat;
    if (coupon) change.coupon = coupon;
    if (this.latestProration) {
      change.proration_date = this.latestProration.proration_date;
      change.sub_item_id =
        this.profServ.latestProfile.subscription.items.data[0].id;
    }
    //		if (!this.profServ.latestProfile.stripeCards || this.profServ.latestProfile.subscription_card_declined) {
    let th = this;
    if (
      !this.profServ.latestProfile.stripeCards ||
      this.profServ.latestProfile.subscription_card_declined
    ) {
      this.stripe.createToken(this.card).then(function (result) {
        if (result.error) {
          // Inform the user if there was an error
          th.cardError = result.error.message;
          th.profServ.sub_processing = null;
        } else {
          th.latestCard = result.token.card;
          change.tok = result.token.id;
          th.api.changeSubscription(th.slimProfile(), change).then((result) => {
            if (th.util.isError(result)) {
              th.util.errorDialog(result.data);
              th.profServ.sub_processing = null;
            } else {
              if (
                result.data &&
                result.data.action &&
                result.data.action == "handlePayment"
              )
                th.stripe
                  .handleCardPayment(result.data.piSecret)
                  .then((result) => {
                    if (result.error) {
                      th.util.errorDialog(result.error);
                      th.profServ.sub_processing = null;
                    } else th.postSubProcessing();
                  });
              else th.postSubProcessing();
            }
          });
        }
      });
    } else
      this.api.changeSubscription(this.slimProfile(), change).then((result) => {
        if (th.util.isError(result)) {
          th.util.errorDialog(result.data);
          th.profServ.sub_processing = null;
        } else {
          if (
            result.data &&
            result.data.action &&
            result.data.action == "handlePayment"
          )
            th.stripe.handleCardPayment(result.data.piSecret).then((result) => {
              if (result.error) {
                th.util.errorDialog(result.error);
                th.profServ.sub_processing = null;
              } else th.postSubProcessing();
            });
          else th.postSubProcessing();
        }
      });
  }

  postSubProcessing() {
    this.profServ.sub_processing = false;
    this.dialog.closeAll();
    this.routing.openSub = true;
    this.routing.toLobby();
  }
  slimProfile() {
    let slim: any = {
      uid: this.profServ.latestProfile.uid,
    };

    if (this.profServ.latestProfile.stripeID)
      slim.stripeID = this.profServ.latestProfile.stripeID;
    if (this.profServ.latestProfile.subscription)
      slim.subscription = this.profServ.latestProfile.subscription;
    if (this.profServ.latestProfile.locationData)
      slim.locationData = this.profServ.latestProfile.locationData;
    if (this.profServ.latestProfile.email)
      slim.email = this.profServ.latestProfile.email;
    return slim;
  }
  prorate(planIndex: number) {
    this.profServ.sub_processing = true;
    let plan = this.configServ.getPlanByIndex(planIndex);
    let change: any = {
      plan: plan.code,
      interval: this.profServ.latestProfile.subInterval,
      currency: this.profServ.latestProfile.currency,
      level: plan.level,
      player_limit: plan.player_limit,
      sub_item_id: this.profServ.latestProfile.subscription.items.data[0].id,
    };
    let prorateProm: Promise<any> = this.api.prorate(
      this.slimProfile(),
      change
    );
    let th: any = this;
    prorateProm.then((result) => {
      if (th.util.isError(result)) {
        th.util.errorDialog(result.data);
        th.profServ.sub_processing = null;
      } else {
        th.latestProration = result.data;
        th.profServ.sub_processing = false;
      }
    });
    return prorateProm;
  }

  cancelSubscription() {
    this.profServ.sub_processing = true;
    let th: any = this;
    this.api
      .cancelSubscription(
        this.profServ.uid,
        this.profServ.latestSubscription.id,
        this.profServ.latestSubscription.code
      )
      .then((result) => {
        if (th.util.isError(result)) {
          th.util.errorDialog(result.data);
          th.profServ.sub_processing = null;
        } else th.profServ.sub_processing = false;
      });
  }
  reactivateSubscription() {
    this.profServ.sub_processing = true;
    let th: any = this;
    this.api
      .reactivateSubscription(
        this.profServ.uid,
        this.profServ.latestSubscription.id,
        this.profServ.latestSubscription.plan.id,
        this.profServ.latestSubscription.code
      )
      .then((result) => {
        if (th.util.isError(result)) {
          th.util.errorDialog(result.data);
          th.profServ.sub_processing = null;
        } else th.profServ.sub_processing = false;
      });
  }
  payInvoice() {
    this.profServ.sub_processing = true;
    let th = this;
    this.stripe.createToken(this.card).then(function (result) {
      if (result.error) {
        // Inform the user if there was an error
        th.cardError = result.error.message;
        th.profServ.sub_processing = null;
      } else {
        th.cardError = null;
        th.api
          .payInvoice(
            th.profServ.uid,
            th.profServ.latestProfile.stripeID,
            result.token.id
          )
          .then((result) => {
            if (th.util.isError(result)) {
              th.util.errorDialog(result.data);
              th.profServ.sub_processing = null;
            } else th.postSubProcessing();
          });
      }
    });
  }
  addCard(uid: string) {
    this.profServ.sub_processing = true;
    let th = this;
    this.stripe.createToken(this.card).then(function (result) {
      if (result.error) {
        // Inform the user if there was an error
        th.cardError = result.error.message;
        th.profServ.sub_processing = null;
      } else {
        th.cardError = null;
        th.api
          .addCard(uid, th.profServ.latestProfile.stripeID, result.token.id)
          .then((result) => {
            if (th.util.isError(result)) {
              th.util.errorDialog(result.data);
              th.profServ.sub_processing = null;
            } else {
              th.profServ.sub_processing = null;
              th.card.clear();
            }
          });
      }
    });
  }
  deleteCard(cardID: string) {
    this.profServ.sub_processing = true;
    let th = this;
    this.api
      .deleteCard(
        this.profServ.uid,
        this.profServ.latestProfile.stripeID,
        cardID
      )
      .then((result) => {
        if (th.util.isError(result)) {
          th.util.errorDialog(result.data);
          th.profServ.sub_processing = null;
        } else th.profServ.sub_processing = null;
      });
  }
  paymentIcon(brand: string) {
    switch (brand) {
      case "American Express":
        return "/assets/img/payment/americanexpress.png";
      case "Diners Club":
        return "/assets/img/payment/dinersclub.png";
      case "Discover":
        return "/assets/img/payment/discover.png";
      case "JCB":
        return "/assets/img/payment/jcb.png";
      case "MasterCard":
        return "/assets/img/payment/mastercard.png";
      case "Visa":
        return "/assets/img/payment/visa.png";
      default:
        return "none";
    }
  }
  changeDefaultCard(cardID: string) {
    this.profServ.sub_processing = true;
    let th = this;
    this.api
      .changeDefaultCard(
        this.profServ.uid,
        this.profServ.latestProfile.stripeID,
        cardID
      )
      .then((result) => {
        if (th.util.isError(result)) {
          th.util.errorDialog(result.data);
          th.profServ.sub_processing = null;
        } else th.profServ.sub_processing = null;
      });
  }
}
