export const defaults = {
	profileImage: "/assets/img/anonymous.png",
	chipsets: {
		0: "1,5,25,100,500",
		1: "5,25,100,500,1000",
		2: "25,100,500,1000,5000",
		3: "100,500,1000,5000,25000"
	},
	tournDefaults: {
		buyin: 10,
		chipset: "25,100,500,1000,5000",
		expPlayers: 9,
		actPlayers: 9,
		duration: 5,
		managePayouts: true,
		manageRegistrations: true,
		antes: false,
		anteType: "standard",
		rebuys: false,
		initSmallBlind: 5,
		startingStack: 10000,
		tournamentType: 'freezeout',
		reentryRatio: 0.333,
		rebuyRatio: 1,
		addonRatio: 0.75,
		addonChipsRatio: 1.5,
		perTable: 9
	},
	bvLogo: "/assets/img/logo.png"
};
