import { Injectable } from '@angular/core';
import firebase from 'firebase/app';
import { AppStatsService } from './app-stats.service';
import { RotateService } from './rotate.service'
import { ApiService } from './api.service'

@Injectable()
export class LocationService {

	constructor(
		private appStats: AppStatsService,
		private rotateServ: RotateService,
		private api: ApiService
	) {

	}
	public updateLocationData(uid: string) {
		let geo:Promise<any> = this.api.geoLoc()
		let th:any = this
		geo.then((resp)=>{
			let locationData = resp.data
			th.updateTVA(locationData.country, uid);
			th.appStats.updateLocationStats(locationData);
			th.rotateServ.init(locationData.country)
		})
		
	};
	public updateTVA(loc: any, uid: string) {
		if (loc) {
			let update: any = {};
			let ratesRef: any = firebase.database().ref('config/rates')
			ratesRef.child(loc).once("value", (snapshot) => {
				var rate = snapshot.val();
				if (!rate)
					rate = 0;
				update.vat = rate;
				if (rate > 0) {
					update.currency = "EUR";
					update.currSymbol = "€";
				}
				else {
					update.currency = "USD";
					update.currSymbol = "$";
				}
				let profRef: any = firebase.database().ref('profiles/' + uid);
				profRef.update(update);
			})
		}

	}
}
