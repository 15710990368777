import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AngularFireDatabase, AngularFireList } from "@angular/fire/database";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import firebase from "firebase/app";

@Injectable()
export class TransConfigService {
  languages: Observable<any[]>;
  public latestLanguages: string[] = [];

  constructor(
    private db: AngularFireDatabase,
    private trans: TranslateService
  ) {
    this.languages = db.list("config/languages").valueChanges(); // TODO refactor the above objects to lists
    this.languages.subscribe((results) => {
      results.sort((a, b) => {
        if (a.localizedName < b.localizedName) {
          return -1;
        }
        if (a.localizedName > b.localizedName) {
          return 1;
        }
        return 0;
      });
      this.latestLanguages = results;
    });
  }
}
