import * as Sentry from "@sentry/browser";
import { Injectable } from "@angular/core";
import {
  AngularFireDatabase,
  AngularFireObject,
  AngularFireList,
} from "@angular/fire/database";
import firebase from "firebase/app";
import { AuthService } from "./auth.service";
import { TrackingService } from "./tracking.service";
import { UtilService } from "./util.service";
import { TranslateService } from "@ngx-translate/core";
import { defaults } from "../shared/defaults";
import { AppStatsService } from "./app-stats.service";
import { LocationService } from "./location.service";
import { ClubService } from "./club.service";
import { ConfigService } from "./config.service";
import { RoutingService } from "./routing.service";
import { ApiService } from "./api.service";
import { TdDialogService } from "@covalent/core/dialogs";
import { DateAdapter, NativeDateAdapter } from "@angular/material/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { RotateService } from "./rotate.service";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { CookieService } from "ngx-cookie-service";

@Injectable()
export class ProfileService {
  profileAF: AngularFireObject<any>;
  profile: Observable<any>;
  clubsAF: AngularFireList<any>;
  clubs: Observable<any[]>;
  latestClubs: any[];
  chipsetsAF: AngularFireList<any>;
  chipsets: Observable<any[]>;
  subscriptionAF: AngularFireObject<any>;
  subscription: Observable<any>;
  templatesAF: AngularFireList<any>;
  templates: Observable<any[]>;
  latestTemplates: any[];
  joinRequestsAF: AngularFireList<any>;
  joinRequests: Observable<any[]>;
  latestJoinRequests: any[];
  rootRef: any;
  latestProfile: any;
  uid: string;
  needProfileInit: boolean;
  needClubsInit: boolean;
  clubLoaded: boolean = false;
  tournaments: any;
  latestChipsets: any[];
  chipsetArr: any[];
  sub_processing: boolean;
  latestSubscription: any;
  clubCreated: boolean = false;
  subCurrency: string;
  subInterval: string;
  planIndex: number;
  amountDue: number;
  needSubsInit: boolean;
  loadingApproveJoin: boolean = false;
  disableDelete: boolean;
  noClubs: boolean;
  private _isAdmin: boolean;
  constructor(
    private db: AngularFireDatabase,
    private auth: AuthService,
    private tracking: TrackingService,
    private util: UtilService,
    private routing: RoutingService,
    private appStats: AppStatsService,
    private dialog: MatDialog,
    private translate: TranslateService,
    public loc: LocationService,
    public clubServ: ClubService,
    private configServ: ConfigService,
    private dialogServ: TdDialogService,
    private snackBar: MatSnackBar,
    private rotateServ: RotateService,
    private dateAdapter: DateAdapter<NativeDateAdapter>,
    private api: ApiService,
    private cookieService: CookieService
  ) {
    this.rootRef = firebase.database().ref();
    let userSub = this.auth.user.subscribe((userData) => {
      if (userData && userData.uid) {
        this.clubServ.lobbyIndex = 0;
        this.uid = userData.uid;
        this.needProfileInit = true;
        this.needClubsInit = true;
        this.needSubsInit = true;
        let path = "profiles/" + userData.uid;
        let chipsetsPath = path + "/chipsets";
        let joinRequestsPath = path + "/joinRequests";
        let subscriptionPath = path + "/subscription";
        let templatePath = "templates/" + userData.uid;
        this.profileAF = db.object(path);
        this.profile = this.profileAF.valueChanges();
        this.chipsetsAF = db.list(chipsetsPath);
        this.chipsets = this.chipsetsAF.valueChanges();
        this.joinRequestsAF = db.list(joinRequestsPath);
        this.joinRequests = this.joinRequestsAF.valueChanges();
        let jrSub = this.joinRequests.subscribe((results) => {
          this.latestJoinRequests = results;
        });

        this.subscriptionAF = db.object(subscriptionPath);
        this.subscription = this.subscriptionAF.valueChanges();
        this.templatesAF = db.list(templatePath);
        this.templates = this.templatesAF.snapshotChanges().pipe(
          map((actions) => {
            return actions.map((action) => ({
              key: action.key,
              ...action.payload.val(),
            }));
          })
        );
        let templSub = this.templates.subscribe((results) => {
          this.latestTemplates = results;
        });
        let clubsSub: any;
        let profSub = this.profile.subscribe((profileData) => {
          if (!profileData) profileData = {};
          //					this.clubServ.uid = profileData.uid;
          this.tournaments = profileData.tournaments;
          // this.latestProfile = [];
          this.latestProfile = profileData;
          this.disableDelete =
            this.latestProfile && this.latestProfile.clubs
              ? Object.keys(this.latestProfile.clubs).length < 2
              : true;
          this.noClubs =
            this.latestProfile && this.latestProfile.clubs
              ? !(Object.keys(this.latestProfile.clubs).length < 1)
              : true;
          this._isAdmin = this.configServ.isAdmin(profileData.uid);
          this.clubsAF = db.list(path + "/clubs");
          this.clubs = this.clubsAF.valueChanges();
          clubsSub = this.clubs.subscribe((results) => {
            this.latestClubs = results;
          });
          if (profileData.clubs && this.needClubsInit) {
            this.clubCreated = true;
            this.needClubsInit = false;
            this.loadClub(profileData);
          }
          if (!profileData.clubs) this.clubServ.noClub();
          this.checkLanguage(profileData);
          if (this.needProfileInit) this.initProfile(profileData, userData);
          if (profileData.uid && this.needSubsInit) {
            this.needSubsInit = false;
            this.initSubscriptions();
          }
          if (profileData.jwt && profileData.createJWT) {
            this.profileAF.update({
              createJWT: null,
            });
            this.smoochLogin();
          }
        });

        let chipsSubs = this.chipsets.subscribe((results) => {
          if (results) {
            this.latestChipsets = results;
            this.initChipsets();
          }
        });

        this.auth.addSub(profSub);
        this.auth.addSub(chipsSubs);
        this.auth.addSub(clubsSub);
        this.auth.addSub(jrSub);
        this.auth.addSub(templSub);
      } else {
        this.uid = null;
        this.latestProfile = null;
        this.tournaments = null;
        //				this.clubServ.uid = null;
        this.needProfileInit = true;
        this.needClubsInit = true;
        this.clubCreated = false;
        this.clubLoaded = false;
        this.latestChipsets = [];
        this.chipsetArr = [];
        this.sub_processing = false;
        this.latestSubscription = null;
      }
    });
  }
  disableDeleteClub() {
    this.disableDelete = Object.keys(this.latestProfile.clubs).length < 2;
  }
  setLastClub(clubID) {
    if (this.latestProfile.lastClub != clubID)
      this.profileAF.update({
        lastClub: clubID,
      });
  }
  private updateSubLevel(level: number, player_limit: number) {
    let profUpdate: any = {
      subLevel: level,
      player_limit: player_limit,
    };
    for (let cid in this.latestProfile.clubs) {
      let club: any = this.latestProfile.clubs[cid];
      if (club.role == "Owner") {
        profUpdate["clubs/" + club.clubID + "/subLevel"] = level;
        this.rootRef.child("clubs").child(club.clubID).update({
          subLevel: level,
          player_limit: player_limit,
        });
      }
    }

    this.profileAF.update(profUpdate);
  }
  private initSubscriptions() {
    let th = this;
    let subSub = this.subscription.subscribe((results) => {
      if (results) {
        th.latestSubscription = results;
        if (
          results.metadata &&
          results.metadata.level &&
          !results.metadata.player_limit
        ) {
          th.subscriptionAF.update({
            "metadata/player_limit": this.configServ.getPlayerLimit(
              results.code
            ),
          });
        } else {
          if (
            th.latestSubscription.metadata &&
            (th.latestSubscription.metadata.level !=
              th.latestProfile.subLevel ||
              th.latestSubscription.metadata.player_limit !=
                th.latestProfile.player_limit)
          )
            th.updateSubLevel(
              th.latestSubscription.metadata.level,
              th.latestSubscription.metadata.player_limit
            );
          if (
            results.plan &&
            results.plan.currency &&
            (!th.latestProfile ||
              results.plan.currency.toUpperCase() != th.latestProfile.currency)
          )
            th.profileAF.update({
              currency: results.plan.currency.toUpperCase(),
            });
        }
      } else {
        th.latestSubscription = null;
        if (th.latestProfile && th.latestProfile.subLevel != 0)
          th.updateSubLevel(0, 9);
      }
    });
    this.auth.addSub(subSub);
  }
  private loadClub(profileData: any) {
    if (profileData.lastClub) this.clubServ.init(profileData.lastClub);
    else this.loadFirstClub(profileData);
  }
  public loadFirstClub(profileData?: any) {
    let prof: any;
    if (profileData) prof = profileData;
    else prof = this.latestProfile;
    if (prof && prof.clubs) {
      for (let c in prof.clubs) {
        if (prof.clubs[c].clubID != this.clubServ.deletedClub) {
          this.clubServ.init(prof.clubs[c].clubID);
          this.setLastClub(prof.clubs[c].clubID);
          break;
        }
      }
    } else this.clubServ.noClub();
  }
  private checkLanguage(profileData: any) {
    let locale: string = this.translate.getBrowserCultureLang();
    if (locale) this.dateAdapter.setLocale(locale);
    if (
      profileData.selLang &&
      profileData.selLang != this.translate.currentLang
    ) {
      //this.translate.currentLang = profileData.selLang
      this.translate.use(profileData.selLang);
      this.translate.reloadLang(profileData.selLang);
    }
  }
  private initProfile(profileData: any, userData: any) {
    if (this.needProfileInit) {
      let sendInBlue = {
        email: "",
        name: "",
        lang: "",
        country: "",
      };
      this.needProfileInit = false;
      const tmpProvider = this.auth.getProvider(userData);
      const trackParams = {
        name: this.getDisplayName(userData, tmpProvider),
        uid: userData.uid,
        method: profileData.provider,
      };
      let update: any = {};
      let newUser: boolean;
      if (!profileData.uid) {
        newUser = true;
        update.uid = userData.uid;
        update.currency = "USD";
        update.subInterval = "annual";
        update.refuseNewsletter = this.cookieService.get("refuseNewsletter");
        if (update.refuseNewsletter === "false") {
          this.api.geoLoc().then((res) => {
            sendInBlue.email = userData.providerData[0].email;
            sendInBlue.name = this.getDisplayName(userData, tmpProvider);
            sendInBlue.lang = this.translate.getBrowserLang();
            sendInBlue.country = res.data.country;
            this.api.createContactSIB(sendInBlue);
          });
        }
        if (this.subCurrency) update.currency = this.subCurrency;
        if (this.subInterval) update.interval = this.subInterval;
        this.appStats.signup();
        if (tmpProvider == "password") {
          firebase.auth().currentUser.sendEmailVerification();
          update.verificationSent = true;
        }
        this.tracking.track("sign_up", trackParams);
        this.tracking.fbTrack("track", "CompleteRegistration", {
          uid: this.uid,
        });
      } else {
        if (!profileData.currency) {
          update.currency = "USD";
          if (this.subCurrency) update.currency = this.subCurrency;
        }
        if (!profileData.subInterval) {
          update.subInterval = "annual";
          if (this.subInterval) update.subInterval = this.subInterval;
        }
        this.tracking.track("login", trackParams);
      }

      if (!profileData.provider) update.provider = tmpProvider;
      if (!profileData.dispName)
        update.dispName = this.getDisplayName(userData, update.provider);
      if (
        !profileData.imgURL ||
        profileData.imgType == update.provider ||
        profileData.imgURL == "/images/anonymous.jpg" ||
        profileData.imgURL == "/assets/img/anonymous.png"
      )
        update.imgURL = this.getImgURL(userData);
      if (!profileData.clubs && !this.clubCreated) {
        this.clubCreated = true;
        this.clubServ.quickClub(
          update.dispName || profileData.dispName,
          profileData.subLevel || 0,
          profileData.player_limit || 9,
          update.imgURL || profileData.imgURL || "/assets/img/anonymous.png"
        );
      }
      if (!profileData.email) update.email = userData.providerData[0].email;
      if (!profileData.createDate)
        update.createDate = firebase.database.ServerValue.TIMESTAMP;
      update.lastLogin = firebase.database.ServerValue.TIMESTAMP;
      update.sortLogin = 0 - Date.now();
      if (profileData.connections)
        update.connections = profileData.connections + 1;
      else update.connections = 1;
      if (!profileData.selLang) {
        update.detectedLang = this.translate.getBrowserLang();
        update.selLang = update.detectedLang;
      }
      if (!profileData.chipsets) update.chipsets = defaults.chipsets;
      if (!profileData.defaults) update.defaults = defaults.tournDefaults;
      if (
        tmpProvider == "password" &&
        userData.emailVerified == false &&
        profileData.verificationSent
      )
        update.prevVerified = true;
      this.updateProfile(update);
      if (!profileData.locationData || !profileData.locationData.city)
        this.loc.updateLocationData(userData.uid);
      else this.rotateServ.init(profileData.locationData.country);
      if (
        profileData.locationData &&
        profileData.locationData.country &&
        !profileData.currency
      )
        this.loc.updateTVA(profileData.locationData.country, userData.uid);
      if (this.latestProfile.jwt) {
        this.smoochLogin();
      } else this.createJWT();
      let scope = Sentry.getCurrentScope();
      scope.setUser({
        id: this.uid,
        email: profileData.email,
        username: profileData.dispName,
      });
      //this.lokitIdentify(userData, profileData, update)
      if (profileData.stripeID)
        this.api.setStripeCards(profileData.uid, profileData.stripeID);
      if (newUser) {
        setTimeout(() => {
          this.api.sendEvent("EVT_NEW_USER", this.latestProfile);
        }, 4000);
      }
    }
  }
  /*   lokitIdentify(userData: any, profileData: any, update: any) {
    if (this.auth && this.auth.lokit && this.auth.lokit.identify)
      this.auth.lokit.identify(
        environment.lokitUID,
        environment.lokitProjectID,
        userData.uid,
        profileData.selLang || update.detectedLang
      );
  } */
  smoochLogin() {
    if (this.auth.smoochReady && this.latestProfile) {
      let adminURL = "https://admin.blindvalet.com/search/" + this.uid;
      this.auth.Smooch.login(this.uid, this.latestProfile.jwt).then(() => {
        let smoochUser: any = this.auth.Smooch.getUser();
        if (
          smoochUser &&
          (smoochUser.givenName != this.latestProfile.dispName ||
            smoochUser.email != this.latestProfile.email ||
            !smoochUser.properties ||
            smoochUser.properties.search != adminURL)
        )
          this.auth.Smooch.updateUser({
            givenName: this.latestProfile.dispName,
            email: this.latestProfile.email,
            properties: {
              search: adminURL,
            },
          });
      });
    } else
      setTimeout(() => {
        this.smoochLogin();
      }, 1000);
  }
  createJWT() {
    this.profileAF.update({
      createJWT: true,
    });
  }
  private updateProfile(update: any) {
    this.profileAF.update(update);
  }

  private getDisplayName(userData: any, provider: string) {
    if (provider == "password")
      return this.util.extractName(userData.providerData[0].email);
    else return userData.providerData[0].displayName || "";
  }
  updateUser(
    newName: string,
    imageUrl: string,
    imageType: string,
    language: string
  ) {
    let profileUpdates = {};
    let uid = this.uid;
    profileUpdates[`profiles/${uid}/imgURL`] = imageUrl;
    profileUpdates[`profiles/${uid}/imgType`] = imageType;
    profileUpdates[`profiles/${uid}/dispName`] = newName;
    profileUpdates[`profiles/${uid}/selLang`] = language;
    for (var club in this.latestProfile.clubs) {
      profileUpdates[`clubs/${club}/members/${uid}/memberName`] = newName;
      profileUpdates[`clubs/${club}/members/${uid}/sortName`] =
        newName.toLowerCase();
      profileUpdates[`clubs/${club}/members/${uid}/imgURL`] = imageUrl;
    }
    for (var tournament in this.latestProfile.tournaments) {
      profileUpdates[`tournaments/${tournament}/players/${uid}/dispName`] =
        newName;
    }
    this.rootRef.update(profileUpdates);
  }
  private getImgURL(userData: any) {
    if (userData.providerData[0].photoURL)
      return userData.providerData[0].photoURL;
    else return defaults.profileImage;
  }

  updateEmail(email: string) {
    let profile = {};
    let uid = this.uid;
    profile[`profiles/${uid}/email`] = email;
    this.rootRef.update(profile);
  }

  initChipsets() {
    this.chipsetArr = [];
    for (let chipset of this.latestChipsets) {
      let tempChips = chipset.split(",");
      this.chipsetArr.push(this.util.arrStrToNumber(tempChips));
    }
  }
  updateChipsets() {
    let tmpArr = this.chipsetArr.slice();
    this.chipsetsAF.remove();
    for (let chipset of tmpArr) this.chipsetsAF.push(chipset.toString());
  }
  isAdmin() {
    return this._isAdmin;
  }
  getProfileForUserData(uid: string, tournID: string) {
    let path = "profiles/" + uid;
    return (this.profile = this.db.object(path).valueChanges());
  }
  getImageUrlForConnectedUser() {
    return this.auth.getUserConnected().imageUrl;
  }
  uploadProfileImage(
    img64: string,
    callback: (imgURL: string, imgType: string) => void,
    failure: (error: any) => void
  ) {
    var profObj = this;
    var profImageRef = firebase
      .storage()
      .ref()
      .child(this.uid)
      .child("profileImage");
    profImageRef
      .putString(img64, "data_url")
      .then(() => {
        profImageRef.getDownloadURL().then(function (url) {
          callback(url, "custom");
        });
      })
      .catch((error) => {
        failure(error);
      });
  }
  inviteJoin(clubID: string, inviteID: string, clubName: string) {
    let inviteRef = this.rootRef
      .child("public")
      .child(clubID)
      .child("invites")
      .child(inviteID);
    inviteRef.once("value", (snapshot) => {
      let invite = snapshot.val();
      if (invite && invite.email) {
        if (
          this.latestProfile &&
          this.latestProfile.clubs &&
          this.latestProfile.clubs[clubID]
        )
          this.translate
            .get(["ALREADY_MEMBER", "CLOSE"])
            .subscribe((res: any) => {
              this.dialogServ.openAlert({
                message: res["ALREADY_MEMBER"],
                title: clubName,
                closeButton: res["CLOSE"],
              });
            });
        else this.joinClub(clubID, this.latestProfile, inviteID);
      } else
        this.translate
          .get(["INVALID_INVITE", "CLOSE"])
          .subscribe((res: any) => {
            this.dialogServ.openAlert({
              message: clubName,
              title: res["INVALID_INVITE"],
              closeButton: res["CLOSE"],
            });
          });
    });
  }
  approveJoin(request: any) {
    this.loadingApproveJoin = true;
    this.joinClub(this.clubServ.clubID, request);
    this.api.deleteRequest(this.clubServ.clubID, request.uid);
  }
  joinClub(clubID: string, profile: any, inviteID?: string) {
    let joinProm: Promise<string> = this.api.joinClub(
      clubID,
      profile,
      inviteID
    );
    joinProm.then((result: any) => {
      if (result.data == "JOINED_CLUB")
        this.translate.get(["JOINED_CLUB", "CLOSE"]).subscribe((res: any) => {
          this.dialogServ.openAlert({
            message: res["JOINED_CLUB"],
            title: clubID,
            closeButton: res["CLOSE"],
          });
        });
      else if (result.data == "REQUEST_APPROVED")
        this.translate
          .get(["REQUEST_APPROVED", "CLOSE"])
          .subscribe((res: any) => {
            this.dialogServ.openAlert({
              message: res["REQUEST_APPROVED"],
              title: profile.dispName,
              closeButton: res["CLOSE"],
            });
          });
      else if (result.data == "CLUB_NOT_FOUND")
        this.translate
          .get(["CLUB_NOT_FOUND", "CLOSE"])
          .subscribe((res: any) => {
            this.dialogServ.openAlert({
              message: clubID,
              title: res["CLUB_NOT_FOUND"],
              closeButton: res["CLOSE"],
            });
          });
      this.loadingApproveJoin = false;
    });
  }

  createJoinRequest(club: any) {
    return new Promise((resolve, reject) => {
      let update: any = {};
      update["clubs/" + club.clubID + "/joinRequests/" + this.uid] = {
        uid: this.uid,
        dispName: this.latestProfile.dispName,
        imgURL: this.latestProfile.imgURL,
      };
      if (this.latestProfile.email)
        update["clubs/" + club.clubID + "/joinRequests/" + this.uid].email =
          this.latestProfile.email;
      update["profiles/" + this.uid + "/joinRequests/" + club.clubID] = club;
      update["grants/" + this.uid + "/" + club.owner] = "full";
      this.rootRef.update(update).then((data) => {
        resolve(true);
        this.translate.get(["REQUEST_SENT"]).subscribe((res: any) => {
          this.snackBar.open(res["REQUEST_SENT"] + ": " + club.clubName, null, {
            duration: 4000,
          });
        });
      });
    });
  }
  changeInterval(interval: string) {
    this.latestProfile.subInterval = interval;
    this.profileAF.update({ subInterval: interval });
  }
  minSubLevel(level: number) {
    if (
      this.latestProfile &&
      this.latestProfile.subscription &&
      this.latestProfile.subLevel >= level
    )
      return true;
    else return false;
  }
}
