import { Injectable } from "@angular/core";
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from "@angular/fire/database";
import { Observable } from "rxjs";

@Injectable()
export class ConfigService {
  adminsAF: AngularFireList<any>;
  admins: Observable<any[]>;
  plansAF: AngularFireList<any>;
  plans: Observable<any[]>;
  ratesAF: AngularFireList<any>;
  rates: Observable<any[]>;
  couponsAF: AngularFireObject<any>;
  coupons: Observable<any>;
  latestPlans: any;
  latestAdmins: any[];
  latestCoupons: any[];
  constructor(private db: AngularFireDatabase) {
    let configPath: string = "config";
    this.adminsAF = this.db.list(configPath + "/admins");
    this.admins = this.adminsAF.valueChanges();
    this.plansAF = this.db.list(configPath + "/plans");
    this.ratesAF = this.db.list(configPath + "/rates");
    this.rates = this.ratesAF.valueChanges();
    this.couponsAF = this.db.object(configPath + "/coupons");
    this.coupons = this.couponsAF.valueChanges();
    this.admins.subscribe((results) => {
      if (results) this.latestAdmins = results;
    });
    this.coupons.subscribe((results) => {
      if (results) this.latestCoupons = results;
    });
    this.db.database
      .ref(configPath + "/plans")
      .once("value")
      .then((snapshot) => {
        this.latestPlans = snapshot.val();
      });
  }
  isAdmin(uid: string) {
    if (this.latestAdmins && this.latestAdmins.length) {
      for (let admin of this.latestAdmins) if (admin == uid) return true;
    }
    return false;
  }
  getPlan(planID: string) {
    if (this.latestPlans)
      for (let plan of this.latestPlans) if (plan.code == planID) return plan;
    return null;
  }
  getPlanIndex(planID: string) {
    if (this.latestPlans)
      for (let i = 0; i < this.latestPlans.length; i++) {
        const plan = this.latestPlans[i];
        if (plan.code == planID) return i;
      }
    return -1;
  }
  getPlanName(planID?: string) {
    let plan: any;
    if (planID == "ANTE") return "Ante";
    if (planID) plan = this.getPlan(planID);
    else plan = this.getPlan("KITCHEN_TABLE");

    if (plan) return plan.name;
    else return null;
  }
  getPlanByIndex(index: number) {
    if (this.latestPlans) return this.latestPlans[index];
    return null;
  }
  getPlayerLimit(planID: string) {
    if (planID == "ANTE") return -1;
    else {
      let plan = this.getPlan(planID);
      return plan.player_limit;
    }
  }
  getCoupon(id: string) {
    id = id.toUpperCase();
    if (this.latestCoupons) return this.latestCoupons[id];
    else return null;
  }
}
