import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { FirebaseTransLoader } from "../../shared/firebase-trans-loader";
import { MissingTranslationHandler } from "@ngx-translate/core";
import { MyMissingTranslationHandler } from "../../shared/firebase-trans-loader";
import { AngularFireDatabase } from "@angular/fire/database";
import { ConnectionRouterComponent } from "../../shared/connection-router/connection-router.component";
import { ExtraScrollComponent } from "../../shared/extra-scroll/extra-scroll.component";
import { CloseDialogButtonComponent } from "../../shared/close-dialog-button/close-dialog-button.component";
import { TournamentService } from "../../providers/tournament.service";
import { AuthService } from "../../providers/auth.service";
import { ClubService } from "../../providers/club.service";
import { ProfileService } from "../../providers/profile.service";
import { AppStatsService } from "../../providers/app-stats.service";
import { LocationService } from "../../providers/location.service";
import { TrackingService } from "../../providers/tracking.service";
import { UtilService } from "../../providers/util.service";
import { ResizeTextComponent } from "../../shared/resize-text/resize-text.component";
import { SiteFooterComponent } from "../../shared/site-footer/site-footer.component";
import { LeagueService } from "../../providers/league.service";
import { SubscriptionService } from "../../providers/subscription.service";
import { EmailService } from "../../providers/email.service";
import { LogProcessService } from "../../providers/log-process.service";
import { AudioService } from "../../providers/audio.service";
import { RotateService } from "../../providers/rotate.service";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MaxPlayersComponent } from "../../shared/max-players/max-players.component";
import { PrefToggleComponent } from "../../shared/pref-toggle/pref-toggle.component";
import { AnnouncementComponent } from "../../shared/announcement/announcement.component";
import { LoadingOverlayComponent } from "../../shared/loading-overlay/loading-overlay.component";

export function FbTransLoaderFactory(db: AngularFireDatabase) {
  return new FirebaseTransLoader(db);
}
@NgModule({
  declarations: [
    ConnectionRouterComponent,
    ExtraScrollComponent,
    CloseDialogButtonComponent,
    ResizeTextComponent,
    SiteFooterComponent,
    MaxPlayersComponent,
    PrefToggleComponent,
    AnnouncementComponent,
    LoadingOverlayComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: FbTransLoaderFactory,
        deps: [AngularFireDatabase],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MyMissingTranslationHandler,
      },
    }),
  ],
  exports: [
    ConnectionRouterComponent,
    ExtraScrollComponent,
    CloseDialogButtonComponent,
    TranslateModule,
    ResizeTextComponent,
    SiteFooterComponent,
    MaxPlayersComponent,
    PrefToggleComponent,
    AnnouncementComponent,
    LoadingOverlayComponent,
  ],
  entryComponents: [MaxPlayersComponent],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        AuthService,
        TournamentService,
        ClubService,
        ProfileService,
        AppStatsService,
        LocationService,
        TrackingService,
        SubscriptionService,
        LeagueService,
        EmailService,
        LogProcessService,
        AudioService,
        RotateService,
        UtilService,
      ],
    };
  }
}
