import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Subscription } from "rxjs";
import { AngularFireAuth } from "@angular/fire/auth";
import firebase from "firebase/app";
import "firebase/auth";
import { RoutingService } from "./routing.service";
import { TrackingService } from "./tracking.service";
import { TranslateService } from "@ngx-translate/core";
import { MatDialog } from "@angular/material/dialog";
import { ConfigService } from "./config.service";
import * as smooch from "smooch/lib";

@Injectable()
export class AuthService {
  user: Observable<firebase.User>;
  subscribers: Subscription[] = [];
  userInfo: any;
  altRoute: string;
  uid: string;
  Smooch: any;
  smoochReady: boolean = false;
  //lokit: any;
  engageParams: any;
  constructor(
    public afAuth: AngularFireAuth,
    private tracking: TrackingService,
    private translate: TranslateService,
    public dialog: MatDialog,
    private routing: RoutingService,
    private configServ: ConfigService
  ) {
    // firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
    //this.lokit = (<any>window).lokit;
    this.user = afAuth.authState;
    this.user.subscribe((userData) => {
      if (userData) {
        this.uid = userData.uid;
        this.userInfo = {
          uid: userData.uid,
          userName: userData.displayName,
          email: userData.email,
          imageUrl: userData.providerData[0].photoURL || "",
        };
        //if (this.getProvider(userData) == "password" && !userData.emailVerified)
        //routing.toVerify();
        /* 				if (userData.providerData) {
					this.userInfo['']
				} */
        tracking.identify(userData.uid);
        dialog.closeAll();
      } else this.uid = null;
    });
    this.Smooch = smooch.default;
    this.Smooch.on("ready", () => {
      this.smoochReady = true;
    });
    this.Smooch.init({ appId: "58bd2d75ac568f2d006c5f44" });
  }

  socialLogin(method: string) {
    let provider: any;
    if (method == "google") {
      provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope("email");
      (<any>window).history.pushState("Blind Valet", "Blind Valet", "/lobby");
      this.afAuth.signInWithRedirect(provider).catch((e: any) => {
        console.log(e.code);
      });
    } else if (method == "facebook") {
      provider = new firebase.auth.FacebookAuthProvider();
      provider.addScope("email");
      //this.afAuth.signInWithPopup(provider).catch((e: any) => {
      this.afAuth.signInWithRedirect(provider).catch((e: any) => {
        if (e.code == "auth/popup-blocked" || "auth/popup-closed-by-user")
          this.afAuth.signInWithRedirect(provider);
        else console.log(e.code);
      });
    }
  }

  logout() {
    let sub: any;
    while (this.subscribers && this.subscribers.length) {
      sub = this.subscribers.pop();
      if (sub) sub.unsubscribe();
    }
    this.Smooch.logout();
    /* 		if (this.lokit && this.lokit.logout)
			this.lokit.logout() */
    setTimeout(() => {
      this.afAuth.signOut();
    });
  }
  addSub(sub: Subscription) {
    this.subscribers.push(sub);
  }
  loginEmail(email: string, password: string) {
    let th = this;
    this.afAuth
      .signInWithEmailAndPassword(email || "", password || "")
      .catch(function (error: any) {
        th.translate.get(th.stripCode(error.code)).subscribe((res: string) => {
          alert(res);
        });
      });
  }
  createEmail(email: string, password: string) {
    let th = this;
    this.afAuth
      .createUserWithEmailAndPassword(email || "", password || "")
      .catch(function (error: any) {
        th.translate.get(th.stripCode(error.code)).subscribe((res: string) => {
          alert(res);
        });
      });
  }
  resetPassword(email: string) {
    let th = this;
    this.afAuth.sendPasswordResetEmail(email || "").then(
      function () {
        th.translate.get("PASSWORD_RESET_SENT").subscribe((res: string) => {
          alert(res);
        });
      },
      function (error: any) {
        th.translate.get(th.stripCode(error.code)).subscribe((res: string) => {
          alert(res);
        });
      }
    );
  }
  updateEmailAddress(email: string, password: any) {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.userInfo.email, password)
        .then(function (userCredential) {
          firebase
            .auth()
            .fetchSignInMethodsForEmail(email)
            .then((res) => {
              if (res.includes("password"))
                resolve("auth/email-already-in-use");
              else {
                userCredential.user
                  .updateEmail(email)
                  .then(() => {
                    resolve("Email updated");
                  })
                  .catch((error) => {
                    resolve(error);
                  });
              }
            })
            .catch((error) => {
              console.log("Error", error);
              resolve(error);
            });
        })
        .catch((error) => {
          resolve(error);
        });
    });
  }

  stripCode(errorCode: string) {
    if (errorCode && errorCode.length && errorCode.split)
      return errorCode.split("/")[1];
    else return errorCode;
  }
  getProvider(userData: any) {
    let tmpProvider = userData.providerData[0].providerId;
    if (tmpProvider == "google.com") tmpProvider = "google";
    else if (tmpProvider == "facebook.com") tmpProvider = "facebook";
    return tmpProvider;
  }
  /**
   * get user connected
   */
  getUserConnected() {
    return this.userInfo;
  }
}
