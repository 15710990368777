import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-player-moves',
  templateUrl: './player-moves.component.html',
  styleUrls: ['./player-moves.component.scss']
})
export class PlayerMovesComponent implements OnInit {

  constructor(
  	 @Inject(MAT_DIALOG_DATA) public data?:any
  	) { }

  ngOnInit() {
  }
}
