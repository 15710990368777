import { Injectable } from "@angular/core";
import firebase from "firebase/app";
import { UtilService } from "./util.service";

@Injectable()
export class RotateService {
  rotate: any;
  rotateArr: any[];
  constructor(private util: UtilService) {
    firebase
      .database()
      .ref("rotate")
      .once("value", (snapshot) => {
        this.rotate = snapshot.val();
        this.init("world");
      });
  }
  init(country: string) {
    var frCountries = [
      "France",
      "Guadeloupe",
      "French Guiana",
      "Martinique",
      "French Polynesia",
      "Réunion",
      "New Caledonia",
      "Saint Pierre and Miquelon",
      "Saint-Barthélemy",
    ];
    if (frCountries.indexOf(country) != -1)
      this.rotateArr = this.util.getObjArray(this.rotate["fr"]);
    else this.rotateArr = this.util.getObjArray(this.rotate["world"]);
  }
}
