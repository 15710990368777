import { BrowserModule, Title, Meta } from "@angular/platform-browser";
import { NgModule, ErrorHandler, Injectable } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule, Routes } from "@angular/router";
import { AngularFireModule } from "@angular/fire";

import { environment } from "../environments/environment";
import { AppComponent } from "./app.component";
import { AngularFireDatabaseModule } from "@angular/fire/database";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { AngularFireDatabase } from "@angular/fire/database";
import { TransConfigService } from "./providers/trans-config.service";
import { FormsModule } from "@angular/forms";
import { VerifyComponent } from "./verify/verify.component";
import { RoutingService } from "./providers/routing.service";
import { CanActivateProfile } from "./providers/can-activate-profile";
import "firebase/storage";
import "firebase/functions";
import { HttpClientModule } from "@angular/common/http";
import { DisconnectedComponent } from "./shared/disconnected/disconnected.component";
import { ServiceWorkerModule } from "@angular/service-worker";
import { init } from "@sentry/angular";
import { createErrorHandler } from "@sentry/angular";
import {
  SharedModule,
  FbTransLoaderFactory,
} from "./bv-mods/shared/shared.module";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { FirebaseTransLoader } from "./shared/firebase-trans-loader";
import { MissingTranslationHandler } from "@ngx-translate/core";
import { MyMissingTranslationHandler } from "./shared/firebase-trans-loader";
import { PlayerMovesComponent } from "./shared/player-moves/player-moves.component";
import { ConfigService } from "./providers/config.service";
import { MatIconModule } from "@angular/material/icon";
import { MatDialogModule } from "@angular/material/dialog";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatChipsModule } from "@angular/material/chips";
import { CovalentMediaModule } from "@covalent/core/media";
import { CovalentDialogsModule } from "@covalent/core/dialogs";
import { CookieService } from "ngx-cookie-service";
import { LiveComponent } from "./live/live.component";
import { CapFirstLettersPipe } from "./shared/cap-first-letters.pipe";

init({
  dsn: "https://a444667a7ce7454595f991db1d207361@sentry.io/262509",
  // ...
});

const languageCodes = [
  "ar",
  "bg",
  "bn",
  "cs",
  "da",
  "de",
  "el",
  "en",
  "es",
  "et",
  "fi",
  "fr",
  "gu",
  "he",
  "hi",
  "hr",
  "hu",
  "id",
  "it",
  "ja",
  "kn",
  "ko",
  "lt",
  "lv",
  "ml",
  "mr",
  "ms",
  "nl",
  "no",
  "pa",
  "pl",
  "pt-BR",
  "pt-PT",
  "ro",
  "ru",
  "sk",
  "sl",
  "sr",
  "sv",
  "ta",
  "te",
  "th",
  "tr",
  "uk",
  "vi",
  "zh-CN",
];

const generateLanguageRoutes = (codes) => {
  return codes.map((code) => ({
    path: code,
    loadChildren: () =>
      import("./bv-mods/home/home.module").then((m) => m.HomeModule),
  }));
};

const appRoutes: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("./bv-mods/home/home.module").then((m) => m.HomeModule),
  },
  ...generateLanguageRoutes(languageCodes),
  {
    path: "lobby",
    loadChildren: () =>
      import("./bv-mods/lobby/lobby.module").then((m) => m.LobbyModule),
  },
  {
    path: "subscription",
    loadChildren: () =>
      import("./bv-mods/subscription/subscription.module").then(
        (m) => m.SubscriptionModule
      ),
  },
  { path: "verify", component: VerifyComponent },
  {
    path: "members",
    loadChildren: () =>
      import("./bv-mods/members/members.module").then((m) => m.MembersModule),
  },
  {
    path: "tournament/:tournID",
    loadChildren: () =>
      import("./bv-mods/tournament/tournament.module").then(
        (m) => m.TournamentModule
      ),
  },
  {
    path: "invite/:clubID/:inviteID",
    loadChildren: () =>
      import("./bv-mods/invite/invite.module").then((m) => m.InviteModule),
  },
  { path: "live", component: LiveComponent },
  {
    path: "",
    redirectTo: "/",
    pathMatch: "full",
  },
  { path: "**", redirectTo: "/" },
];

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    VerifyComponent,
    DisconnectedComponent,
    PlayerMovesComponent,
    LiveComponent,
    CapFirstLettersPipe,
  ],
  imports: [
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    RouterModule.forRoot(appRoutes, { relativeLinkResolution: "legacy" }),
    BrowserAnimationsModule, // Conflict with routing after login
    FormsModule,
    MatIconModule,
    MatDialogModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatChipsModule,
    HttpClientModule,
    CovalentMediaModule,
    CovalentDialogsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: FbTransLoaderFactory,
        deps: [AngularFireDatabase],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MyMissingTranslationHandler,
      },
    }),
    SharedModule.forRoot(),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
  ],
  providers: [
    TransConfigService,
    RoutingService,
    CanActivateProfile,
    ConfigService,
    Title,
    Meta,
    CookieService,
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: false,
      }),
    },
  ],
  bootstrap: [AppComponent],
  entryComponents: [DisconnectedComponent, PlayerMovesComponent],
})
export class AppModule {}
