import * as Sentry from "@sentry/browser";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
declare let gtag: Function;

@Injectable()
export class TrackingService {
  fbq: any;
  private prod: boolean;
  constructor() {
    this.fbq = (<any>window).fbq;
    if (environment.server == "blindvalet.com") {
      this.prod = true;
      let scope = Sentry.getCurrentScope();
      scope.setExtra("commit", (<any>window).commitID);
    }
  }
  public fbTrack(type: string, command: string, data?: any) {
    if (this.prod)
      if (data) this.fbq(type, command, data);
      else this.fbq(type, command);
  }
  private breadCrumb(message: string, data?: any) {
    let options: any = {
      category: "event",
      message: message,
      level: "info",
    };
    if (data) options.data = data;
    Sentry.addBreadcrumb(options);
  }
  public track(eventName: string, params?: any) {
    if (this.prod) {
      this.breadCrumb(eventName, params);
      if (params) gtag("event", eventName, params);
      else gtag("event", eventName);
    }
  }
  public identify(uid: string) {
    if (this.prod) gtag("set", { uid: uid });
  }
  public subscription(subID: string, code: string) {
    if (this.prod) {
      this.breadCrumb("Subscription " + subID + " " + code);
      let value: number = 0;
      if (code === "CLUB") value = 100;
      else if (code === "HOME_GAME") value = 40;
      else value = 48;
      gtag("event", "purchase", {
        name: code,
        transaction_id: subID,
        value: value,
      });
      this.fbTrack("track", "Purchase", {
        value: value,
        currency: "USD",
      });
    }
  }
  public tournTab(tabName: string, tournID: string) {
    if (this.prod) gtag("config", "UA-6671359-2", { page_path: "/" + tabName });
  }
  public screenView(screenName: string) {
    if (this.prod)
      gtag("config", "UA-6671359-2", { page_path: "/" + screenName });
  }
  public toCheckout(id: string) {
    if (this.prod) gtag("config", "UA-6671359-2", { page_path: "/toCheckout" });
  }
  public toTournament(id: string) {
    if (this.prod)
      gtag("config", "UA-6671359-2", { page_path: "/toTournament" });
  }
  public clickSignup(placement: string) {
    if (this.prod) gtag("event", "open_signup", { placement: placement });
  }
  public homeEngage(step, action) {
    if (this.prod) gtag("event", "home_engage_" + step + "_" + action);
  }
}
