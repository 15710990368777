import { Injectable, SecurityContext } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable()
export class EmailService {
  public emails: AngularFireList<any>
  constructor(
    private db: AngularFireDatabase,
    private sanitizer: DomSanitizer
  ) {
    this.emails = db.list("emails");
  }

  addEmail(subject: string, body: string, to: string, from: string) {
    this.emails.push({
      subject: this.sanitizer.sanitize(SecurityContext.HTML, subject),
      body: this.sanitizer.sanitize(SecurityContext.HTML, body),
      to: to,
      from: from
    });
  }

  clearEmailQueue() {
    this.emails.remove();
  }
}